*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// https://stackoverflow.com/questions/28261287/how-to-change-btn-color-in-bootstrap/50973207#50973207
// https://stackoverflow.com/questions/38792005/how-to-change-the-bootstrap-primary-color
// https://stackoverflow.com/questions/8596794/customizing-bootstrap-css-template/50410667#50410667

/* import the Bootstrap */
@import 'bootstrap';

/* ------- customize primary button color -------- */
$mynewcolor: #101a4e;

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: $mynewcolor !important;
  border-color: $mynewcolor !important;
}
